import { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthentication } from '../../authentication/useAuthentication';

interface AdminNavLinkProps {
  to: string;
  text?: string;
}

export function AdminNavLink({
  to,
  text,
  children
}: Readonly<PropsWithChildren<AdminNavLinkProps>>) {
  const auth = useAuthentication();
  const isAdmin = auth?.currentUser?.roles.includes('ADMIN');

  return isAdmin ? (
    <NavLink
      to={to}
      className="nav-link d-flex align-items-center"
    >
      {children}
      {text}
    </NavLink>
  ) : null;
}
