import { Page } from '@42.nl/spring-connect';
import { Pagination } from '@42.nl/ui';
import { PageSize } from './PageSize/PageSize';

interface PaginationBarProps<T> {
  page: Page<T>;
  useBigSizes?: boolean;
  onChange: (page: { page: number; size: number }) => void;
}

export function PaginationBar<T>({
  page,
  useBigSizes = false,
  onChange
}: Readonly<PaginationBarProps<T>>) {
  function onSize(size: number) {
    onChange({
      page: page.number,
      size
    });
  }

  function onPage(number: number) {
    onChange({
      page: number,
      size: page.size
    });
  }

  return (
    <div className="d-flex flex-row align-items-center justify-content-center justify-content-md-between w-100 pt-3">
      <div className="d-none d-md-block">
        <PageSize
          value={page.size}
          useBigSizes={useBigSizes}
          onChange={(size) => onSize(size)}
        />
      </div>
      <Pagination
        page={page}
        showPreviousAndNextButtons={page.totalPages > 2}
        onChange={(number) => onPage(number)}
        showTotalElements={false}
      />
    </div>
  );
}
