import { isEmpty } from 'lodash';
import { InfoTooltip } from '../../../components/InfoTooltip/InfoTooltip';
import { getText } from '../../../i18n/LocalizedText';
import Data from '../../../types/canonical/Data';
import Subject from '../../../types/canonical/Subject';
import { WidgetType } from '../../types/WidgetType';
import WidgetField from '../../WidgetField/WidgetField';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  data: Data;
  hideHeader?: boolean;
  isTitle: boolean;
};

export function SubjectsItem({
  field,
  type,
  data,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  let subjects = data.subjects || [];

  const subjectType = field.referenceType;
  if (subjectType) {
    subjects = subjects.filter(
      (subject) => subject.reference.type === subjectType
    );
  }

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(subjects)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() => (
        <ul className={type === WidgetType.SIDEBAR ? 'list-unstyled' : ''}>
          {subjects?.map((subject, index) => (
            <li key={index}>
              <SubjectItem subject={subject} />
            </li>
          ))}
        </ul>
      )}
    </FieldSection>
  );
}

type SubjectItemProps = {
  subject: Subject;
};

function SubjectItem({ subject }: Readonly<SubjectItemProps>) {
  const description = getText(subject.descriptions);

  return (
    <>
      {getText(subject.reference.names)}
      <InfoTooltip tooltip={description} />
    </>
  );
}
