import { useQueryParams } from '@42.nl/react-url';
import { useLocation } from 'react-router-dom';
import { Paged } from '../../components/PaginationBar/Paged';

export type TabPageQueryParams = {
  type: string;
  entityType: string;
  isSubTab: string;
  hasWidgets: string;
  visible: string;
} & Paged;

export function useTabPageQueryParams() {
  const location = useLocation();

  return useQueryParams<TabPageQueryParams>({
    location,
    defaultQueryParams: defaultTabPageQueryParams(),
    debugName: 'TabPage'
  });
}

export function defaultTabPageQueryParams(): TabPageQueryParams {
  return {
    type: '',
    entityType: '',
    isSubTab: '',
    hasWidgets: '',
    visible: '',
    page: 1,
    size: 10,
    sort: 'sequence,ASC'
  };
}
