import { get, makeResource, post } from '@42.nl/spring-connect';
import { QueryParams, search } from '../filters/Search';
import AcademicYear from './AcademicYear';
import Study from './canonical/Study';
import { Product, ProductTypeEnum } from './Product';
import { Rule } from './Rule';

const baseUrl = '/api/study';

export default class SimpleStudy
  extends makeResource<SimpleStudy>(baseUrl)
  implements Product
{
  id!: number;
  data!: Study;
  productType!: string;

  static years(code: string): Promise<AcademicYear[]> {
    return get(`${baseUrl}/${code}/years`);
  }

  static async import(study: SimpleStudy) {
    return post(`${baseUrl}/${study.id}/import`, null);
  }

  static rules(product: Product): Promise<Rule[]> {
    return get(`${baseUrl}/${product.id}/rules`);
  }

  static async search(queryParams: QueryParams) {
    return search<SimpleStudy>(ProductTypeEnum.STUDY, baseUrl, queryParams);
  }
}
