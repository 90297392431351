import { useTranslation } from 'react-i18next';
import CheckboxMultiSelect from '../../components/CheckboxMultiSelect/CheckboxMultiSelect';
import { widgetApi } from '../service/widgetApi';
import { useWidgetSelectOptions } from './hooks/useWidgetSelectOptions';

interface WidgetSelectProps {
  id: string;
  entityType: string | undefined;
}

export default function WidgetSelect({
  id,
  entityType
}: Readonly<WidgetSelectProps>) {
  const { t } = useTranslation(['widget']);

  const { data: widgets } = widgetApi.list.useQuery();

  const options = useWidgetSelectOptions(widgets, entityType);

  return (
    <CheckboxMultiSelect
      id={id}
      className="mb-3"
      label={t('widget:ADD_WIDGET')}
      options={options}
    />
  );
}
