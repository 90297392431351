import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getText } from '../i18n/LocalizedText';
import { Product, getProductUrl } from '../types/Product';

type Props = {
  product: Product;
  className?: string;
};

export function ProductLink({ product, className }: Readonly<Props>) {
  return (
    <span className={classNames(className)}>
      <Link
        key={product.data.uid}
        to={getProductUrl(product)}
        className="me-2"
      >
        {product.data.code}
      </Link>
      <span>{getText(product.data.names)}</span>
    </span>
  );
}
