import { Col } from 'reactstrap';
import { getText } from '../../../i18n/LocalizedText';
import { Product } from '../../../types/Product';
import Widget from '../../types/Widget';
import { getVisibleSortedWidgetFields } from '../../utils/WidgetUtils';
import { WidgetItem } from '../../WidgetItem/WidgetItem';

type Props = {
  widget: Widget;
  product: Product;
  expanded: boolean;
  expandable: boolean;
};

export function SidebarWidget({
  widget,
  product,
  expanded,
  expandable
}: Readonly<Props>) {
  return (
    <Col className="details__content">
      {!widget.hideHeader ? (
        <Col className="details__content__header">
          {getText(widget.labels, widget.name)}
        </Col>
      ) : null}
      <Col className="details__content__container">
        {getVisibleSortedWidgetFields(widget).map((field) => (
          <WidgetItem
            key={`field-${field.id}`}
            field={field}
            type={widget.type}
            hideHeader={widget.hideHeader}
            product={product}
            expanded={expanded}
            expandable={expandable}
            isTitle={false}
          />
        ))}
      </Col>
    </Col>
  );
}
