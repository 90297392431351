import React, { useMemo } from 'react';
import { Button } from '@42.nl/ui';
import { chain, isEmpty } from 'lodash';
import { getText } from '../../i18n/LocalizedText';
import Data from '../../types/canonical/Data';
import { Product, ProductType, getTemplate } from '../../types/Product';
import { widgetApi } from '../service/widgetApi';
import Widget from '../types/Widget';
import { WidgetType } from '../types/WidgetType';
import { getVisibleSortedWidgetFields } from '../utils/WidgetUtils';
import WidgetField from '../WidgetField/WidgetField';

interface WidgetButtonProps {
  product: Product;
  children?: React.ReactNode;
}

export function WidgetButtons({
  product,
  children
}: Readonly<WidgetButtonProps>) {
  const { data: widgets } = widgetApi.list.useQuery();

  const buttons = useMemo(() => {
    const relevantWidgets = buildWidgets(widgets, product.productType);
    return relevantWidgets.flatMap((widget) =>
      getButtons(widget, product.data)
    );
  }, [widgets, product.productType, product.data]);

  return (
    <div className="d-flex align-items-center justify-content-start justify-content-md-end flex-wrap gap--0_4rem">
      {buttons.map((button, index) => (
        <a
          key={index}
          href={button.url}
          target="_blank"
          rel="noreferrer"
          aria-label={button.name}
        >
          <Button
            type="button"
            className="btn-light"
          >
            {button.name}
          </Button>
        </a>
      ))}

      {children}
    </div>
  );
}

function buildWidgets(
  widgets: Widget[] | undefined,
  productType: ProductType
): Widget[] {
  if (!widgets) {
    return [];
  }

  return chain(widgets)
    .filter((widget) => widget.entityType === productType)
    .filter((widget) => widget.type === WidgetType.BUTTONS)
    .filter('visible')
    .sortBy('sequence', 'id')
    .value();
}

export class WidgetButton {
  name?: string;
  url: string;

  constructor(field: WidgetField, data: Data) {
    this.name = getText(field.labels, '?');
    this.url = getTemplate(field.name, data);
  }
}

function getButtons(widget: Widget, data: Data): WidgetButton[] {
  return getVisibleSortedWidgetFields(widget)
    .filter((field) => field.type === 'URL')
    .map((field) => {
      return new WidgetButton(field, data);
    })
    .filter((button) => !isEmpty(button.url));
}
