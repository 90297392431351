/* istanbul ignore file */

import { Suspense, useEffect } from 'react';
import { AuthenticationProvider } from '@42.nl/authentication';
import { configureConstraint, loadConstraints } from '@42.nl/jarb-final-form';
import { configureEnums, loadEnums } from '@42.nl/react-spring-enums';
import { LoadingPage } from '@42.nl/ui';
import {
  QueryClient,
  QueryClientProvider,
  useQuery
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; // By default, only included in development bundles
import { createRoot } from 'react-dom/client';
import {
  ProgressBar,
  progressBarFetch,
  setOriginalFetch
} from 'react-fetch-progressbar';
import { I18nextProvider, useTranslation } from 'react-i18next';
import App from './app/App';
import { getAuthenticationService } from './app/authentication/AuthenticationService';
import { bootApp } from './app/authentication/Login/transitions/transitions';
import { Loader } from './app/components';
import { CustomerStyle } from './app/components/CustomerStyle/CustomerStyle';
import FlashMessages from './app/components/FlashMessages/FlashMessages';
import { ParameterProvider } from './app/parameters/ParameterProvider';
import { configureApiWithAuthentication } from './core/api/api';
import handleErrors from './core/error';
import i18n, { configureTranslations } from './core/i18n';
import { unregister } from './core/registerServiceWorker';
import './styles/app/app.scss';

// Import to make Webpack include favicons for index.html
require.context('./images/favicons', true, /\.(ico|png|svg|webmanifest)$/);

declare global {
  interface Window {
    reactFetchProgressBarInitialized?: boolean;
  }
}

if (window.reactFetchProgressBarInitialized === undefined) {
  setOriginalFetch(window.fetch);
  window.reactFetchProgressBarInitialized = true;

  // @ts-expect-error Allow overriding of fetch
  window.fetch = progressBarFetch;
}

configureApiWithAuthentication();

configureConstraint({
  constraintsUrl: '/api/constraints'
});

configureEnums({
  enumsUrl: '/api/enums'
});

handleErrors();
loadConstraints();
loadEnums();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // After 5 minutes, data becomes stale and re-fetch may occur
      gcTime: 1000 * 60 * 5 // After 5 minutes of no component using the data, it is removed from memory
    }
  }
});

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <Suspense fallback={<LoadingPage />}>
      <AuthenticationProvider>
        <QueryClientProvider client={queryClient}>
          <ParameterProvider onLoad={[configureTranslations]}>
            <I18nextProvider i18n={i18n}>
              <ProgressBar />
              <FlashMessages />
              <BootApp>
                <App />
              </BootApp>
            </I18nextProvider>
          </ParameterProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthenticationProvider>
    </Suspense>
  );

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept();
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();

function BootApp({ children }: Readonly<{ children: React.ReactNode }>) {
  const state = useQuery({
    queryKey: ['boot'],
    queryFn: bootApp
  });

  const { t } = useTranslation('translation');
  const title = t('BROWSER_TITLE');

  getAuthenticationService().initialize();

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Loader state={state}>
      {() => <CustomerStyle>{children}</CustomerStyle>}
    </Loader>
  );
}
