import { useState } from 'react';
import { addSuccess } from '@42.nl/react-flash-messages/lib';
import { useEnum } from '@42.nl/react-spring-enums';
import { Button } from '@42.nl/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AppFrame, Check, DataTable, Loader } from '../components';
import { DeleteConfirmButton } from '../components/DeleteButton/DeleteConfirmButton';
import EnumLabel from '../components/EnumLabel/EnumLabel';
import EnumSelect from '../components/EnumSelect/EnumSelect';
import { getText } from '../i18n/LocalizedText';
import { PRODUCT_TYPES } from '../types/Product';
import { useTabPageQueryParams } from './hooks/useTabPageQueryParams.hook';
import { invalidateTabCaches, tabApi } from './service/tabApi';
import TabForm, { TabFormData } from './TabForm';
import Tab from './types/Tab';
import { toTabPageUrl } from './utils/tabUrlUtils';

export default function TabList() {
  const navigate = useNavigate();
  const queryParams = useTabPageQueryParams();
  const queryClient = useQueryClient();
  const state = tabApi.page.useQuery({ variables: queryParams });

  const { t } = useTranslation(['tab', 'translation', 'widget']);
  const [tab, setTab] = useState<TabFormData>();
  const booleans = useEnum('BooleanType');

  function changeSort(sort: string) {
    navigate(toTabPageUrl({ ...queryParams, sort }), { replace: true });
  }

  function pageChanged(page: number, size: number) {
    navigate(toTabPageUrl({ ...queryParams, page, size }), { replace: true });
  }

  function filterChanged(name: string, value: string) {
    navigate(toTabPageUrl({ ...queryParams, [name]: value, page: 1 }), {
      replace: true
    });
  }

  const columns = [
    {
      label: t('tab:COLUMNS.NAME'),
      sort: 'name',
      width: 120,
      cell: (row: Tab) => row.name
    },
    {
      label: t('tab:COLUMNS.ENTITY_TYPE'),
      sort: 'entityType',
      width: 100,
      filter: () => (
        <EnumSelect
          selected={queryParams.entityType}
          values={PRODUCT_TYPES}
          onSelect={(value) => filterChanged('entityType', value)}
        />
      ),
      cell: (row: Tab) => {
        return <EnumLabel value={row.entityType} />;
      }
    },
    {
      label: t('tab:COLUMNS.LABEL'),
      width: 150,
      cell: (row: Tab) => getText(row.labels, row.name)
    },
    {
      label: t('tab:COLUMNS.VISIBLE'),
      sort: 'visible',
      width: 50,
      filter: () => (
        <EnumSelect
          selected={queryParams.visible}
          values={booleans}
          onSelect={(value) => filterChanged('visible', value)}
        />
      ),
      cell: (row: Tab) => <Check checked={row.visible} />
    },
    {
      label: t('tab:COLUMNS.SUB_TAB'),
      width: 50,
      filter: () => (
        <EnumSelect
          selected={queryParams.isSubTab}
          values={booleans}
          onSelect={(value) => filterChanged('isSubTab', value)}
        />
      ),
      cell: (row: Tab) => (
        <Check checked={row.parentTabs && row.parentTabs.length > 0} />
      )
    },
    {
      label: t('tab:COLUMNS.HAS_WIDGETS'),
      width: 50,
      filter: () => (
        <EnumSelect
          selected={queryParams.hasWidgets}
          values={booleans}
          onSelect={(value) => filterChanged('hasWidgets', value)}
        />
      ),
      cell: (row: Tab) => (
        <Check checked={row.widgets && row.widgets.length > 0} />
      )
    },
    {
      label: t('tab:COLUMNS.SEQUENCE'),
      sort: 'sequence',
      width: 50,
      cell: (row: Tab) => row.sequence
    },
    {
      label: t('tab:COLUMNS.FILTER'),
      sort: 'filter',
      width: 100,
      cell: (row: Tab) => row.filter
    },
    {
      label: '',
      width: 20,
      cell: (row: Tab) => (
        <div className="actions">
          <Button
            className="me-1"
            color="primary"
            onClick={() => setTab(row)}
            icon="edit"
          />
          <DeleteConfirmButton
            name={row.name}
            onDelete={() => deleteTab(row)}
          />
        </div>
      )
    }
  ];

  async function onSubmit(tabFormData: TabFormData) {
    await tabApi.save.mutationFn(tabFormData);

    addSuccess({
      text: t('translation:SAVE_SUCCESS', { name: tabFormData.name })
    });
    setTab(undefined);
    await invalidateTabCaches(queryClient);
  }

  async function deleteTab(tabToDelete: Tab) {
    await tabApi.remove.mutationFn(tabToDelete);

    addSuccess({
      text: t('translation:DELETE_SUCCESS', { name: tabToDelete.name })
    });
    await invalidateTabCaches(queryClient);
  }

  return (
    <AppFrame title={t('tab:TABS')}>
      <Row className="justify-content-center">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Card body>
            <div className="d-flex justify-content-end mb-2">
              <Button
                color="primary"
                icon="add"
                onClick={() => setTab({ labels: [], visible: true })}
              >
                {t('tab:ADD_TAB')}
              </Button>
            </div>

            {tab ? (
              <Modal isOpen={!!tab}>
                <ModalHeader toggle={() => setTab(undefined)}>
                  {getText(tab.labels, t('tab:ADD_TAB'))}
                </ModalHeader>
                <ModalBody>
                  <TabForm
                    initialValues={tab}
                    onCancel={() => setTab(undefined)}
                    onSubmit={onSubmit}
                  />
                </ModalBody>
              </Modal>
            ) : undefined}

            <Loader state={state}>
              {(page) => (
                <DataTable<Tab>
                  width={200}
                  columns={columns}
                  page={page}
                  sort={queryParams.sort}
                  onSort={changeSort}
                  onPage={pageChanged}
                />
              )}
            </Loader>
          </Card>
        </Col>
      </Row>
    </AppFrame>
  );
}
