import { capitalize, isEmpty } from 'lodash';
import { getText } from '../../../i18n/LocalizedText';
import Reference, { displayName } from '../../../references/Reference';
import WidgetField from '../WidgetField';

const getWidgetFieldKey = (field: WidgetField) => {
  return `field-${field.id}`;
};

const getWidgetFieldLabel = (field: WidgetField) => {
  return getText(field.labels, capitalize(field.name));
};

const widgetFieldHasFormat = (field: WidgetField) => {
  return field.format && !isEmpty(field.format);
};

const translateWidgetFields = (values: any[], references: Reference[]) => {
  return values
    .map((value) => {
      if (value instanceof Date) {
        return value.toLocaleDateString();
      }

      return displayName(value, references, value);
    })
    .sort()
    .join(', ');
};

export {
  getWidgetFieldKey,
  getWidgetFieldLabel,
  widgetFieldHasFormat,
  translateWidgetFields
};
