import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Tab from '../../types/Tab';
import { getActiveMainTab, tabIsParent } from '../../utils/parentTabUtils';
import { useTabsContext } from './useTabsContext';

export function useMainTabs(): Tab[] {
  const { sortedTabs = [] } = useTabsContext();
  const location = useLocation();

  return useMemo(() => {
    const parentTabs: Tab[] = sortedTabs.filter(tabIsParent);
    const activeTab = getActiveMainTab(parentTabs, location);

    parentTabs.forEach((tab) => {
      tab.isActiveMain = tab.id === activeTab?.id;
    });

    return parentTabs;
  }, [sortedTabs, location]);
}
