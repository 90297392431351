import { useMemo } from 'react';
import { extend, orderBy } from 'lodash';
import { getText } from '../../../i18n/LocalizedText';
import Widget from '../../types/Widget';

export function useWidgetSelectOptions(
  widgets?: Widget[],
  entityType?: string
): Widget[] {
  return useMemo(() => {
    if (!widgets) {
      return [];
    }

    const mapped = widgets.map((widget) => {
      let name = getText(widget.labels, widget.name);

      const matches = widget.entityType === entityType;
      if (!matches) {
        name = `(${widget.entityType}) ${name}`;
      }

      return extend(widget, { matches, name, labels: [] });
    });

    return orderBy(mapped, ['matches', 'name'], ['desc', 'asc']);
  }, [widgets, entityType]);
}
