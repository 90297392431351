import Additional from './Additional';
import Description from './Description';
import Item from './Item';
import Reference from './Reference';
import Text from './Text';
import Value from './Value';

export default class Qualification {
  id?: string;
  uid!: string;
  code?: string;
  year!: Reference;
  type?: string;
  faculty?: Reference;
  specification?: Reference;
  group?: string;
  degree?: string;
  calculation?: string;
  appraisal?: string;
  names?: Text[];
  descriptions?: Description[];
  additional?: Additional;
  items?: Item[];
  values?: Value[];

  static readonly QUERY_KEY = 'qualifications';
}
