import { useEffect, useState } from 'react';
import { Input } from '@42.nl/ui';
import { forEach, get } from 'lodash';
import { Field } from 'react-final-form';
import { FormFeedback } from 'reactstrap';
import { getLanguages } from '../../core/i18n';
import { LocalizedText } from './LocalizedText';

type Props = {
  id: string;
  label: string;
};

export default function TextInput({ id, label }: Readonly<Props>) {
  return (
    <>
      <label>{label}</label>
      <Field
        name={id}
        render={({ input, meta }) => (
          <TextInputComponent
            input={input}
            meta={meta}
            label={label}
          />
        )}
      />
    </>
  );
}

type TextInputComponentProps = {
  input: any;
  meta: any;
  label: string;
};

function TextInputComponent({
  input,
  meta,
  label
}: Readonly<TextInputComponentProps>) {
  const languages = getLanguages();

  const [texts] = useState<LocalizedText[]>(
    languages.map((language) => ({
      language: language.toUpperCase(),
      value: ''
    }))
  );

  useEffect(() => {
    forEach(input.value, (text: LocalizedText) => {
      const found = texts.find((t) => t.language === text.language);
      if (found) {
        found.value = text.value;
      }
    });
  }, [input.value, texts]);

  function setValue(value: string, language: string) {
    const text = findText(language);
    if (text) {
      text.value = value;
    }
    input.onChange(texts.map((text_) => text_));
  }

  function findText(language: string) {
    return texts.find(
      (text) => text.language?.toUpperCase() === language.toUpperCase()
    );
  }

  function getValue(language: string) {
    return get(findText(language), 'value');
  }

  return (
    <>
      {languages.map((language) => (
        <Input
          id={input.name}
          value={getValue(language)}
          onBlur={input.onBlur}
          onChange={(value) => setValue(value, language)}
          addon={
            <span className="input-group-text">{language.toUpperCase()}</span>
          }
          error={
            meta.error && meta.touched ? (
              <FormFeedback>{meta.error}</FormFeedback>
            ) : null
          }
          label={label}
          hiddenLabel={true}
          key={`${input.name}-${language}`}
        />
      ))}
    </>
  );
}
