import { get, makeResource, post } from '@42.nl/spring-connect';
import { QueryParams, search } from '../filters/Search';
import AcademicYear from './AcademicYear';
import Qualification from './canonical/Qualification';
import { Product, ProductTypeEnum } from './Product';

const baseUrl = '/api/qualification';

export default class SimpleQualification
  extends makeResource<SimpleQualification>(baseUrl)
  implements Product
{
  id!: number;
  data!: Qualification;
  productType!: string;

  static years(code: string): Promise<AcademicYear[]> {
    return get(`${baseUrl}/${code}/years`);
  }

  static async import(qualification: SimpleQualification) {
    return post(`${baseUrl}/${qualification.id}/import`, null);
  }

  static async search(queryParams: QueryParams) {
    return search<SimpleQualification>(
      ProductTypeEnum.QUALIFICATION,
      baseUrl,
      queryParams
    );
  }
}
