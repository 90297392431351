import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Page } from '@42.nl/spring-connect';
import {
  keepPreviousData,
  useQuery,
  UseQueryResult,
  useInfiniteQuery,
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  InfiniteData
} from '@tanstack/react-query';
import { find, get, isString, isUndefined, join, transform } from 'lodash';
import { getProperties, ObjectProperties } from '../filters/Filters';
import {
  FilterOption,
  formatProductsAsFilterOptions
} from '../filters/NumberFilter/FilterOption';
import { QueryParams } from '../filters/Search';
import { groupDetailUrl } from '../groups/GroupDetail/GroupDetail';
import { replaceVariables } from '../i18n/Interpolation';
import { moduleDetailUrl } from '../modules/ModuleDetail/ModuleDetail';
import { qualificationDetailUrl } from '../qualifications/QualificationDetail/QualificationDetail';
import { studyDetailUrl } from '../studies/StudyDetail/StudyDetail';
import { getYearService } from '../years/YearService';
import Data from './canonical/Data';

export type Product = {
  id: number;
  productType: ProductType;
  data: Data;
};

export const PRODUCT_QUERY_KEY = 'products';

export enum ProductTypeEnum {
  FACULTY = 'FACULTY',
  QUALIFICATION = 'QUALIFICATION',
  SPECIFICATION = 'SPECIFICATION',
  STUDY = 'STUDY',
  GROUP = 'GROUP',
  MODULE = 'MODULE'
}

export const PRODUCT_TYPES: string[] = Object.values(ProductTypeEnum).sort();
export type ProductType = (typeof PRODUCT_TYPES)[number];

export type ProductListQueryParams = {
  year: string;
  code?: string[];
  text?: string;
};

export function productIsOfType(
  product: Product,
  targetType: ProductType
): boolean {
  return productTypeMatches(product.productType, targetType);
}

export function productTypeMatches(
  productType: ProductType | string | undefined,
  productTargetType: ProductType
): boolean {
  if (
    isUndefined(productType) ||
    !isString(productType) ||
    !PRODUCT_TYPES.includes(productType)
  ) {
    // eslint-disable-next-line no-console
    console.error(`Invalid product type: ${productType}`);
    return false;
  }

  return productType === productTargetType;
}

export function getProductProperties(product: any): ObjectProperties {
  const properties = getProperties(product);

  properties.year = get(product, 'year.externalId');
  properties.type = get(product, 'type.externalId');
  properties.language = get(product, 'language.externalId');

  for (const [key, value] of Object.entries(product)) {
    if (!properties[key] && value) {
      properties[key] = String(value);
    }
  }
  return properties;
}

export function getTemplate(template: string, data: Data) {
  const variables = transform(
    data.additional?.values || [],
    (result: { [key: string]: string }, value) => {
      result[`${value.name}`] = join(value.values);
    },
    {}
  );

  variables.id = data.id || '';
  variables.code = data.code?.toUpperCase() || '';
  variables.year = data.year.id || '';
  variables.key = data.key?.toString() || '';

  return replaceVariables(template, variables);
}

export function getProductUrl({ data, productType }: Product): string {
  switch (productType) {
    case ProductTypeEnum.QUALIFICATION:
      return qualificationDetailUrl(data);
    case ProductTypeEnum.STUDY:
      return studyDetailUrl(data);
    case ProductTypeEnum.GROUP:
      return groupDetailUrl(data);
    case ProductTypeEnum.MODULE:
      return moduleDetailUrl(data);
    default:
      return '';
  }
}

type LowercaseProductType = Lowercase<ProductTypeEnum>;
type UseProductQueryParams<T extends Product> = {
  productType: LowercaseProductType;
  loadFunction: (code: string, queryParams: any) => Promise<T>;
  code: string;
  queryParams: any;
  filterChanged: (key: string, value: string) => void;
};

export function useProductQuery<T extends Product>({
  productType,
  loadFunction,
  code,
  queryParams,
  filterChanged
}: UseProductQueryParams<T>): UseQueryResult<T> {
  const queryResult = useQuery({
    queryKey: [productType, { code, queryParams }],
    queryFn: () => loadFunction(code, queryParams)
  });
  const { data: product, isSuccess, isError, error } = queryResult;

  useEffect(() => {
    if (isError && error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [isError, error]);

  const previousType = useRef<string | undefined>(undefined);
  useEffect(() => {
    if (isSuccess && product) {
      const newType = product.data.type;
      if (newType && previousType.current !== newType) {
        filterChanged('type', newType);
        previousType.current = newType;
      }
    }
  }, [isSuccess, product, filterChanged]);

  return queryResult;
}

export type ProductDetailPathParams = {
  code: string;
};

export type ProductDetailQueryParams = {
  year: string;
  tab?: string;
  type?: string;
  mainTab?: string;
};

export const useProductSelection = (
  selectedIds: string[],
  loadedProducts: FilterOption[],
  onChange: (values: string[]) => void
) => {
  const cachedSelectedProductsRef = useRef<FilterOption[]>([]);

  const selectedProducts = useMemo(
    () =>
      selectedIds.map((id) => {
        const selectedProduct = find(
          loadedProducts.concat(cachedSelectedProductsRef.current),
          (product) => product.value === id
        );
        return selectedProduct ?? { label: id, value: id };
      }),
    [selectedIds, loadedProducts]
  );

  useEffect(() => {
    cachedSelectedProductsRef.current = selectedProducts;
  }, [selectedProducts]);

  function onSelect(value: string) {
    const newValues = [...selectedIds, value];
    onChange(newValues);
  }

  function onSelectMultiple(values: FilterOption[]) {
    const ids = values.map((value) => value.value);
    const newValues = [...selectedIds, ...ids];
    onChange(newValues);
  }

  function onDeselect(value: string) {
    const newValues = selectedIds.filter((id) => id !== value);
    onChange(newValues);
  }

  function onDeselectAll() {
    onChange([]);
  }

  return {
    selectedProducts,
    onSelect,
    onSelectMultiple,
    onDeselect,
    onDeselectAll
  };
};

type UseInfiniteProductQueryProps<T> = {
  productType: ProductType;
  uniqueKey: string;
  loadFunction: (queryParams: QueryParams) => Promise<Page<T>>;
  size: number;
  initialSearchText?: string;
  onTotalElementsChange?: (newTotalElements: number) => void;
};

type UseInfiniteProductQueryResult = {
  loadedProducts: FilterOption[];
  hasMorePages?: boolean;
  isFetching: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<FilterOption[]>>>;
  handleSearch: (value: string) => void;
  loadedAllPagesRef: React.MutableRefObject<boolean>;
};

export function useInfiniteProductQuery<T extends Product>({
  productType,
  uniqueKey,
  loadFunction,
  size,
  initialSearchText = '',
  onTotalElementsChange
}: UseInfiniteProductQueryProps<T>): UseInfiniteProductQueryResult {
  const [searchText, setSearchText] = useState(initialSearchText);
  const [loadedProducts, setLoadedProducts] = useState<FilterOption[]>([]);
  const loadedAllPagesRef = useRef<boolean>(false);

  const year = getYearService().getCurrentYear().externalId;

  const { hasNextPage, isFetching, fetchNextPage, data } = useInfiniteQuery({
    queryKey: [
      uniqueKey,
      PRODUCT_QUERY_KEY,
      productType,
      year,
      searchText,
      size
    ],
    queryFn: ({ pageParam = 0 }) =>
      loadFunction({
        year: getYearService().getCurrentYear().externalId,
        text: searchText,
        page: pageParam,
        size: size,
        sort: 'code,ASC'
      }).then((response) => {
        loadedAllPagesRef.current = response.last;
        if (onTotalElementsChange) {
          onTotalElementsChange(response.totalElements);
        }
        return formatProductsAsFilterOptions(response.content);
      }),
    initialPageParam: 0,
    enabled: !!productType && productType === ProductTypeEnum.FACULTY,
    placeholderData: keepPreviousData,
    getNextPageParam: (_, pages) =>
      loadedAllPagesRef.current ? undefined : pages.length + 1
  });

  useEffect(() => {
    if (!data) return;

    setLoadedProducts(data.pages.flat());
  }, [data]);

  const handleSearch = useCallback((value: string) => {
    setSearchText(value);
  }, []);

  return {
    loadedProducts,
    isFetching,
    fetchNextPage,
    hasMorePages: hasNextPage,
    handleSearch,
    loadedAllPagesRef
  };
}
