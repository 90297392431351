import { LocalizedText } from '../../i18n/LocalizedText';
import { WidgetFieldType } from './types/WidgetFieldType';

export default class WidgetField {
  id!: number;
  type!: WidgetFieldType;
  name!: string;
  filter?: string;
  format?: string;
  referenceType?: string;
  sequence!: number;
  visible!: boolean;
  hideEmpty!: boolean;
  admin!: boolean;
  labels!: LocalizedText[];
  tooltips!: LocalizedText[];
}
