import { EnumValue } from '@42.nl/react-spring-enums/lib/models';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';

type Props = {
  selected: string;
  values: EnumValue[];
  selectAllLabel?: string;
  onSelect: (value: string) => void;
};

export default function EnumSelect({
  selected,
  values,
  selectAllLabel,
  onSelect
}: Readonly<Props>) {
  const { t } = useTranslation(['enum', 'translation']);

  return (
    <Input
      type="select"
      value={selected}
      onChange={(event) => onSelect(event.target.value)}
    >
      <option value={''}>{selectAllLabel || t('translation:SHOW_ALL')}</option>
      {values.map((value: EnumValue) => {
        const isComplex = typeof value !== 'string';
        const code = isComplex ? value.code : value;
        const displayName = isComplex ? value.displayName : value;

        return (
          <option
            key={code}
            value={code}
          >
            {capitalize(displayName)}
          </option>
        );
      })}
    </Input>
  );
}
