import {
  authInterceptor,
  configureAuthentication,
  getXSRFToken
} from '@42.nl/authentication';
import { configureApi } from '@42.nl/spring-connect';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { getCookie } from 'cookies-next';
import { dateInterceptor } from '../date.interceptor';
import { errorMiddleware } from '../middleware/middleware';

const TOKEN_COOKIE_NAME = 'te_studyguide_token';

export function configureApiWithAuthentication(): AxiosInstance {
  configureAuthentication(); // using defaults

  const api: AxiosInstance = axios.create({
    withCredentials: true,
    headers: {
      common: {
        'X-XSRF-TOKEN': getXSRFToken()
      }
    }
  });

  // Set the bearer token for each request, ensuring up-to-date token
  api.interceptors.request.use(
    (config) => {
      const token = getCookie(TOKEN_COOKIE_NAME);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );

  api.interceptors.response.use(undefined, authInterceptor);
  api.interceptors.response.use(undefined, errorMiddleware);
  api.interceptors.response.use(dateInterceptor);

  configureApi(api);
  return api;
}
