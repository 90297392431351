import {
  QuestionCircleOutlined,
  UsergroupAddOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledDropdown
} from 'reactstrap';
import { toAbout } from '../../../../../../About/links';
import { CurrentUser } from '../../../../../../users/CurrentUser';
import { toUsersApp } from '../../../../../../users/links';
import { AdminNavLink } from '../../../../../AdminNavLink/AdminNavLink';
import LogoutDropdownItem from '../LogoutDropdownItem/LogoutDropdownItem';
import AdminNav from './AdminNav';

type Props = {
  user: CurrentUser | undefined;
  isMobile: boolean;
};

export default function UserNav({ user, isMobile }: Readonly<Props>) {
  const { t } = useTranslation(['topbar', 'translation']);

  return user ? (
    <Nav className={isMobile ? 'mobile-menu-extra' : 'd-none d-lg-flex'}>
      <NavItem className="d-flex">
        <AdminNavLink
          to={toUsersApp()}
          text={t('TOPBAR.USERS')}
        >
          <UsergroupAddOutlined className="me-1" />
        </AdminNavLink>
      </NavItem>

      <AdminNav />

      <UncontrolledDropdown
        nav
        inNavbar
      >
        <DropdownToggle
          nav
          caret
          color="outline-secondary"
        >
          <UserOutlined className="me-1" />
          <span>{user.name}</span>
        </DropdownToggle>

        <DropdownMenu>
          <LogoutDropdownItem />
        </DropdownMenu>
      </UncontrolledDropdown>

      <NavItem className="d-flex">
        <AdminNavLink to={toAbout()}>
          <QuestionCircleOutlined />
        </AdminNavLink>
      </NavItem>
    </Nav>
  ) : null;
}
